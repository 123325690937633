import React from 'react';
import { styled } from 'linaria/react';
import DynamicLink from '../../../ui/Link';

const StyledLink = styled(DynamicLink)`
  text-decoration: none;
  white-space: nowrap;
  padding: 10px 20px;
  background-color: transparent;
  color: ${props => props.labelColor};
  border: none;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.02em;

  &.underline {
    padding: 2px 0;
    border-bottom: 1px solid ${props => props.labelColor};
    font-size: 14px;
  }

  &.solid {
    background-color: ${props => props.backgroundColor};
    :hover {
      background-color: ${props =>
        props.backgroundColor === 'white' ? '#DEDDD9' : '#3e3e3e'};
    }
  }

  &.outlined {
    border: 1px solid ${props => props.labelColor};
    :hover {
      background-color: ${props => props.labelColor};
      color: ${props => props.backgroundColor};
    }
  }
`;

const LinkButton = ({ label, labelColor, theme, link }) => {
  const backgroundColor = labelColor === 'white' ? 'black' : 'white';

  return (
    <StyledLink
      link={link}
      theme={theme && theme}
      labelColor={labelColor && labelColor}
      backgroundColor={backgroundColor && backgroundColor}
      className={theme && theme}
    >
      {label}
    </StyledLink>
  );
};

export default LinkButton;
