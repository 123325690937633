import { styled } from 'linaria/react';
import { theme } from '../Theme';

const SectionWrapper = styled('section')`
  margin: 3.5rem 0 0;
  &:last-child:not(.journal) {
    margin-bottom: 3.5rem;
  }
  &.gap-reset {
    margin: 0;
  }
  &.gap-small {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }
  &.gap-small-top {
    margin-top: 1.75rem;
  }
  &.gap-small-bottom {
    margin-bottom: 1.75rem;
  }
  ${theme.below.md} {
    margin: 1rem 0 0;
    &:last-child:not(.journal) {
      margin-bottom: 1rem;
    }
    &.gap-small {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    &.gap-small-top {
      margin-top: 1rem;
    }
    &.gap-small-bottom {
      margin-bottom: 1rem;
    }
  }
`;

export default SectionWrapper;
