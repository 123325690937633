import React from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import LinkButton from '../../../Storyblok/ui/bloks/LinkButton';
import { H1 } from '../../../ui/Typography';
import { theme } from '../../../Theme';
import SectionWrapper from '../../../Layout/SectionWrapper';
import Image from '../components/Image';
import { Below } from '@jetshop/ui/Breakpoints';
import HeroVideo from '../components/Video';

const FlexibleHeroWrapper = styled(SectionWrapper)`
  height: ${props => (props.isJournal ? '600px' : 'calc(100vh - 105px)')};
  margin-bottom: ${props => (props.isJournal ? '3.5rem' : '0')};
  width: 100%;
  margin-top: 0;
  ${theme.below.md} {
    height: ${props => (props.isJournal ? '485px' : 'calc(100vh - 105px)')};
  }
`;

const TextContainer = styled('div')`
  display: flex;
  gap: 8px;
  flex-direction: column;
  align-items: ${props => props.alignItems || 'center'};
  justify-content: ${props => props.jusifyContent || 'center'};
  padding: 48px 32px;
  height: 100%;
  text-align: center;
  position: ${props => props.isVideo && 'absolute'};
  ${theme.below.md} {
    padding: 32px 16px;
  }
`;

const Title = styled('h1')`
  font-size: 32px;
  line-height: 35.2px;
  font-weight: 600;
  letter-spacing: -0.02em;
  color: var(--text-color, black);
  text-align: ${props =>
    props.alignItems === 'flex-start' ? 'start' : 'center'};
  ${theme.above.md} {
    font-size: 42px;
    line-height: 46.2px;
  }
`;
const Tag = styled('span')`
  letter-spacing: 0.01em;
  font-size: 11px;
  line-height: 14.3px;
  color: var(--text-color, black);
  text-align: ${props =>
    props.alignItems === 'flex-start' ? 'start' : 'center'};
  ${theme.below.md} {
    font-size: 13px;
    line-height: 15px;
  }
`;
const Text = styled('p')`
  text-align: ${props =>
    props.alignItems === 'flex-start' ? 'start' : 'center'};
  color: var(--text-color, black);
  max-width: 520px;
  font-size: 14px;
  line-height: 18.2px;
  letter-spacing: -0.02em;
`;

const heroStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Links = styled('div')`
  display: flex;
  gap: 12px;
  align-items: center;
  margin-top: 24px;
  ${theme.below.md} {
    gap: 8px;
  }
`;

const BottomLinks = styled('div')`
  display: flex;
  gap: 12px;
  align-items: center;
  position: absolute;
  bottom: 48px;
  left: ${props => (props.alignItems === 'flex-start' ? '32px' : '50%')};
  transform: ${props =>
    props.alignItems === 'flex-start' ? 'none' : 'translateX(-50%)'};

  ${theme.below.md} {
    gap: 8px;
    bottom: 32px;
  }
`;

const FlexibleHero = props => {
  const {
    links,
    links_at_bottom,
    content_horizontal_alignment,
    content_vertical_alignment,
    desktop_image,
    heading,
    mobile_image,
    tag,
    text,
    text_color_in_rgb,
    journal_hero
  } = props;
  const showLinksAtBottom =
    links_at_bottom && content_vertical_alignment !== 'end';

  const isMobileVideo =
    mobile_image.filename && mobile_image.filename.endsWith('.mp4');
  const isDesktopVideo =
    desktop_image.filename && desktop_image.filename.endsWith('.mp4');

  return (
    <FlexibleHeroWrapper isJournal={journal_hero}>
      <Below breakpoint="sm">
        {matches => {
          const isVideo = matches ? isMobileVideo : isDesktopVideo;
          const mediaSrc = matches
            ? mobile_image.filename
            : desktop_image.filename;

          return isVideo ? (
            <HeroVideo videoSrc={mediaSrc}>
              <TextContainer
                alignItems={content_horizontal_alignment}
                jusifyContent={content_vertical_alignment}
                isVideo
              >
                {tag && (
                  <Tag
                    alignItems={content_horizontal_alignment}
                    style={{ '--text-color': text_color_in_rgb }}
                  >
                    {tag}
                  </Tag>
                )}
                {heading && (
                  <Title
                    alignItems={content_horizontal_alignment}
                    style={{ '--text-color': text_color_in_rgb }}
                  >
                    {heading}
                  </Title>
                )}
                {text && (
                  <Text
                    alignItems={content_horizontal_alignment}
                    style={{ '--text-color': text_color_in_rgb }}
                  >
                    {text}
                  </Text>
                )}
                {!showLinksAtBottom && links && (
                  <Links>
                    {links &&
                      links.map((link, index) => (
                        <LinkButton
                          key={index}
                          link={link}
                          label={link.label}
                          theme={link.theme}
                          labelColor={link.label_color}
                        />
                      ))}
                  </Links>
                )}
              </TextContainer>
              {showLinksAtBottom && links && (
                <BottomLinks alignItems={content_horizontal_alignment}>
                  {links &&
                    links.map((link, index) => (
                      <LinkButton
                        link={link}
                        key={index}
                        label={link.label}
                        theme={link.theme}
                        labelColor={link.label_color}
                      />
                    ))}
                </BottomLinks>
              )}
            </HeroVideo>
          ) : (
            <Image
              fillAvailableSpace={true}
              src={mediaSrc}
              alt={desktop_image.alt || heading || ''}
              width={matches ? 900 : 2000}
              imageAspect={matches && '4:5'}
              className={heroStyles}
            >
              <TextContainer
                alignItems={content_horizontal_alignment}
                jusifyContent={content_vertical_alignment}
              >
                {tag && (
                  <Tag
                    alignItems={content_horizontal_alignment}
                    style={{ '--text-color': text_color_in_rgb }}
                  >
                    {tag}
                  </Tag>
                )}
                {heading && (
                  <Title
                    alignItems={content_horizontal_alignment}
                    style={{ '--text-color': text_color_in_rgb }}
                  >
                    {heading}
                  </Title>
                )}
                {text && (
                  <Text
                    alignItems={content_horizontal_alignment}
                    style={{ '--text-color': text_color_in_rgb }}
                  >
                    {text}
                  </Text>
                )}
                {!showLinksAtBottom && links && (
                  <Links>
                    {links &&
                      links.map((link, index) => (
                        <LinkButton
                          key={index}
                          link={link}
                          label={link.label}
                          theme={link.theme}
                          labelColor={link.label_color}
                        />
                      ))}
                  </Links>
                )}
              </TextContainer>
              {showLinksAtBottom && links && (
                <BottomLinks alignItems={content_horizontal_alignment}>
                  {links &&
                    links.map((link, index) => (
                      <LinkButton
                        link={link}
                        key={index}
                        label={link.label}
                        theme={link.theme}
                        labelColor={link.label_color}
                      />
                    ))}
                </BottomLinks>
              )}
            </Image>
          );
        }}
      </Below>
    </FlexibleHeroWrapper>
  );
};

export default FlexibleHero;
