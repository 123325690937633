import React from 'react';
import { css, cx } from 'linaria';
import { theme } from '../../../Theme';

const heroVideoStyles = css`
  width: 100%;
  height: 100%;
  margin-bottom: 1.5rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .hidden {
    visibility: hidden;
  }

  ${theme.above.sm} {
    height: 100%;
    margin-bottom: 3.5rem;
  }

  ${theme.below.sm} {
    h1 {
      font-size: 1.5rem;
    }
  }
`;

const HeroVideo = ({ videoSrc, title, children }) => {
  return (
    <div className={heroVideoStyles}>
      <video autoPlay loop muted playsinline>
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {children}
    </div>
  );
};

export default HeroVideo;
